import moment from 'moment';

export const processOfferData = (offers, commodities, cme) => {
  const ret = [];
  offers.forEach(offer => {
    const offerCommodity = commodities.find(comm => comm.id === offer.commodity.category.id);

    if (ret.findIndex(comm => comm.id === offer.commodity.category.id) < 0) {
      const { id, name, tickers, icon } = offerCommodity || {};
      ret.push({ id, name, tickers, icon });
    }
  })

  //sort the return data by commodity alphabetically
  ret.sort((a, b) => {
    if (a.name && b.name) {
      return (a.name < b.name ? -1 : 1)
    }
    return 0;
  })

  //construct the data to render
  ret.forEach(item => {
    const matchingCategories = offers.filter(off => off.commodity.category.id === item.id);
    const onlyLive = matchingCategories.filter(off => off.status.status === "LIVE" && off.offerType.type !== "ASK");
    //sort by date
    onlyLive.sort((a, b) => {
      if (a.commodity.type < b.commodity.type) return -1
      else if (a.commodity.type < b.commodity.type) return 1
      else if (a.commodity.type === b.commodity.type) {
        if (a.deliveryEndDateTime && b.deliveryEndDateTime) {
          return (moment(a.deliveryEndDateTime).isBefore(moment(b.deliveryEndDateTime)) ? -1 : 1)
        }
      }
      return 0;
    })

    onlyLive.forEach(bid => {
      const offerCommodity = commodities.find(comm => comm.id === bid.commodity.category.id);
      //calculate price details
      const futureYearString = bid?.futureYear?.toString() || "-";
      const offerType = offerCommodity.types.find(type => type.type === bid.commodity.type)
      const offerTicker = `${offerType?.ticker?.toUpperCase()}${(((bid || {}).commodity || {}).future || {}).symbol}${(futureYearString || {}).substring(futureYearString.length - 2, futureYearString.length)}`;
      const futurePrice = cme.find(cme => cme.result.symbol === offerTicker);
      const futurePriceBu = (offerCommodity || {}).tickers?.map(t => t.symbol).includes('rs') ? parseFloat(((((futurePrice || {}).result || {}).data || {}).close / (offerCommodity || {}).conversionRate.rate).toFixed(4)) : parseFloat(((((futurePrice || {}).result || {}).data || {}).close / 100).toFixed(4));
      const futurePriceTon = (offerCommodity || {}).tickers?.map(t => t.symbol).includes('rs') ? parseFloat(((((futurePrice || {}).result || {}).data || {}).close)) : parseFloat((((((futurePrice || {}).result || {}).data || {}).close / 100) * (offerCommodity || {}).conversionRate.rate).toFixed(4));
      const offerPriceDetail = {};

      const futureChange = (((futurePrice || {}).result || {}).data || {}).change;
      const futureChangePercent = futureChange !== 0 ? (futureChange || 0).toFixed(4) : null;
      const change = futureChangePercent ? offerTicker.substring(0, offerTicker.length - 3) === 'RS' ? futureChangePercent : futureChangePercent / 100 : '-'
      const fx = (((cme[0] || {}).result || {}).data || {}).close
      let cadBasis = (((futurePriceBu + bid.priceBushel) * fx) - futurePriceBu).toFixed(2)

      //assign basis price
      if (bid.priceType.type === 'BASIS') {
        if (process.env.REACT_APP_USD_CONVERSION === 'true') {
          offerPriceDetail.basisPrice = cadBasis
        } else {
          offerPriceDetail.basisPrice = (bid || {}).priceBushel.toFixed(2)
        }

        //if commodity is canola, asign MT prices
        if ((offerCommodity || {}).tickers.map(t => t.symbol).includes('rs')) {
          offerPriceDetail.basisPrice = (bid || {}).priceTon.toFixed(2);
        }
      } else {
        offerPriceDetail.basisPrice = '-'
      }
      //assign future price
      offerPriceDetail.futurePrice = futurePriceBu.toFixed(4);

      //if commodity is canola, asign MT prices
      if ((offerCommodity || {}).tickers?.map(t => t.symbol).includes('rs')) {
        offerPriceDetail.futurePrice = futurePriceTon.toFixed(4);
      }

      //assign price BU
      if (process.env.REACT_APP_USD_CONVERSION === 'true') {
        offerPriceDetail.priceBu = ((bid.priceBushel + futurePriceBu) * fx).toFixed(2)
      } else {
        offerPriceDetail.priceBu = (bid.priceBushel + futurePriceBu).toFixed(2);
      }

      //assign price MT
      if (process.env.REACT_APP_USD_CONVERSION === 'true') {
        offerPriceDetail.priceMt = ((bid.priceTon + futurePriceTon) * fx).toFixed(2)
      } else {
        offerPriceDetail.priceMt = (bid.priceTon + futurePriceTon).toFixed(2);
      }
      //assign change
      offerPriceDetail.change = change;

      bid.priceDetails = offerPriceDetail;

      // Soybean Offer price calculation
      if (offerCommodity.name === 'Soybean Meal') {
        offerPriceDetail.basisPrice = 0
        offerPriceDetail.futurePrice = 0
        offerPriceDetail.priceBu = "-"
        offerPriceDetail.priceMt = bid?.priceTon
        offerPriceDetail.change = "-"
      }
      //Black Turtle Bean Offer price calculation
      if (offerCommodity.name === 'Black Turtle Bean') {
        offerPriceDetail.basisPrice = 0
        offerPriceDetail.futurePrice = 0
        offerPriceDetail.priceBu = bid?.priceBushel
        offerPriceDetail.priceMt = bid?.priceTon
        offerPriceDetail.priceLbs = bid?.prices?.perLbs
        offerPriceDetail.change = "-"
      }
    })
    item["offers"] = onlyLive;
  })

  return ret;
}

export const getBasis = (offer) => {
  if (offer.priceType && offer.priceType.type === "BASIS") {
    return offer.priceBushel.toFixed(2);
  }
  return "-";
}