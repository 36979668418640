const location = {
	address1: "",
	address2: "Waterloo, ON N2J 4G8",
	addresses: [
		{
			title: "Rosendale Farms",
			address1: "544 Sawmil Rd",
			address2: "Waterloo, ON N2J 4G8",
			coordinate: { lat: 43.4968005567008, lng: -80.4537806204078 },
		}
	],
	phone: "519-744-4941",
	email: "info@rosendalefarms.com",
	coordinate: {
		lat: 43.4968005567008,
		lng: -80.4537806204078,
	},
};

export default location;
